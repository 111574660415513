'use client';

import { Flex, useDisclosure } from '@chakra-ui/react';
import { CfCard, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

import {
  AssetsList,
  CreateAccountCard,
  IntroductionCard,
  InvestmentsList,
  InvestModal,
  LatestTrade,
  LearnSection,
  OnboardingModal,
  PageLayout,
  PerformanceOverTime,
} from '~/components';
import { AllAssetIds, ChartTimeRange } from '~/customTypes';
import {
  useFeatureSetEnabled,
  useGetSystemStatus,
  useGetTokenPrices,
  useGetUser,
  useOnboardingInfo,
  useUserInvestments,
} from '~/hooks';
import { getExploreAssetsSecurities } from '~/utils';

export default function HomePage() {
  const user = useGetUser();
  const investments = useUserInvestments();
  const { isOpen: isInvestModalOpen, onOpen: onOpenInvestModalOpen, onClose: onInvestModalClose } = useDisclosure();
  const {
    isOpen: isOnboardingModalOpen,
    onOpen: onOnboardingModalOpen,
    onClose: onOnboardingModalClose,
  } = useDisclosure();
  const [selectedTimeRange, setSelectedTimeRange] = useState<ChartTimeRange>('pastDay');
  const {
    isOnboarded,
    isOnboardedSecurities,
    isOnboardedCrypto,
    isRejectedOrPending,
    currentOnboardingStatus,
    hasNewTerms,
  } = useOnboardingInfo();
  const systemStatus = useGetSystemStatus();

  const onOpenModal = () => {
    systemStatus.refetch();

    if (isOnboardedSecurities || isOnboardedCrypto) {
      onOpenInvestModalOpen();
    } else {
      onOnboardingModalOpen();
    }
  };

  const { isEnabled } = useFeatureSetEnabled();

  useEffect(() => {
    if (user?.data) {
      mixpanel.identify(`${user.data?.fiId}-${user.data?.userAccountId}`);
    }
  }, [user?.data]);

  // when terms are updated, show them in the onboarding modal
  useEffect(() => {
    if (hasNewTerms) {
      onOnboardingModalOpen();
    }
  }, [hasNewTerms, onOnboardingModalOpen]);

  const tokenPrices = useGetTokenPrices();
  const { isBelowLargeBreakpoint } = useCfBreakpoint();
  const investmentValueTimeSeries = user.data?.balance?.investmentHistoricalValue || {};
  const chartData = investmentValueTimeSeries[selectedTimeRange] || [];
  const showOnboarding = (currentOnboardingStatus && !isOnboarded && !hasNewTerms) || isRejectedOrPending;
  const [defaultAssetId, setDefaultAssetId] = useState<AllAssetIds | undefined>();

  const featuredSecurities = getExploreAssetsSecurities(user.data?.securitiesRiskProfile);

  // determine the default asset to show in the invest modal, after feature set is loaded
  useEffect(() => {
    if (!defaultAssetId && isEnabled(['securities'])) {
      setDefaultAssetId(featuredSecurities.at(0));
    }

    if (!defaultAssetId && !isEnabled(['securities']) && isEnabled(['crypto']) && tokenPrices.data) {
      setDefaultAssetId(tokenPrices.data[0]?.baseToken);
    }
  }, [defaultAssetId, tokenPrices.data, featuredSecurities, isEnabled]);

  return (
    <PageLayout navHeading="Home" hideTopNavBar={!isBelowLargeBreakpoint} bgColor={uiColors.lighthouse()}>
      <Flex gap="3" w="full" flexDir={{ base: 'column', lg: 'row' }}>
        <Flex flexDir="column" gap="inherit" flexGrow="1">
          <PerformanceOverTime
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            chartData={chartData}
            totalValue={investments.data.totalBalances.allInvestments}
            isLoaded={investments.isSuccess}
            valueType="portfolio"
          />

          {showOnboarding && <CreateAccountCard onOnboardingModalOpen={onOpenModal} />}

          {isOnboarded && <InvestmentsList onOpenModal={onOpenModal} />}

          {showOnboarding && <IntroductionCard />}

          <LearnSection />
        </Flex>

        <Flex flexDir="column" gap="inherit" flexBasis="33%" flexShrink="0">
          <CfCard p="0">
            <AssetsList onOpenModal={onOpenModal} />
          </CfCard>

          <CfCard>
            <LatestTrade />
          </CfCard>
        </Flex>
      </Flex>

      {defaultAssetId && (
        <InvestModal isOpen={isInvestModalOpen} onClose={onInvestModalClose} defaultAssetId={defaultAssetId} />
      )}

      {/* conditionally render modal to improve performance */}
      {(showOnboarding || isOnboardingModalOpen) && (
        <OnboardingModal isOpen={isOnboardingModalOpen} onClose={onOnboardingModalClose} hasNewTerms={hasNewTerms} />
      )}
    </PageLayout>
  );
}
